import { trim } from 'lodash';

export function validateCustomerName(value) {
	if (value?.toLowerCase() === 'test') { return true; }
	const [firstName, lastName] = [value?.split(' ')[0], value?.split(' ')[1]];
	if ((firstName && trim(firstName).length > 0 && firstName !== 'default' && firstName !== '') && (lastName && trim(lastName).length > 0 && lastName !== 'default' && lastName !== '')) { return true; }

	return false;
}

export function validateText(value) {
	if (typeof(value) === 'object') { return true; }
	if (value && trim(value).length > 0 && value !== 'default' && value !== '') { return true; }
	return false;
}

export function validateEmail(incomingValue) {
	return String(incomingValue)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
}

export function validateDate(value) {
	if (value === null || value === '') { return false; } else { return true; }
}

export function validateTime(value) {
	if (value.length > 0) { return true; } else { return false; }
}

export function validateAddress(value) {
	// explicitly filter out email address entries
	if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
		return false;
	}

	return value.length > 5;
}

export function validateZip(value) {
	if (!value || value.length === 0) {
		return false;
	}
	if (value.replace(/\s/g,'').match(/^\d{5}$/) || value.replace(/\s/g,'').match(/^([A-Za-z]\d[A-Za-z]\d[A-Za-z]\d)/)) {
		return true;
	} else {
		return false;
	}
}

export function validatePhone(value) {
	if (!value || value.length === 0) {
		return false;
	}
	var strippedPhone = value.replace(/\D/g,'');
	if (strippedPhone.length === 10 || strippedPhone.length === 11) {
		return true;
	} else {
		return false;
	}
}

export function validateBoolForTrue (bool) {
	return !!(bool);
}

