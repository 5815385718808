import React, { Component, Fragment } from 'react';
import { Form } from 'react-bootstrap';
import Header from '../../../global_components/Header';
import RequiredLabel from '../../../global_components/RequiredLabel';
import { getValueFromInputData } from '../../../helpers/objects';
import { IMaskInput } from 'react-imask';

export class ZipPostal extends Component {
	constructor(props) {
		super(props);
		this.zipPostalInput = React.createRef();
	}
	componentDidMount() {
		document.getElementById('customer-zip_postal').focus();
	}
	render() {
		const { inputData, business } = this.props;
		return (
			<Fragment>
				<Header head="Location?" subHead="Let's see if we're in your area. Please enter your zip or postal code." />
				<div className="d-flex justify-content-center">
					<Form.Group className="mb-0">
						<Form.Label className="label">{business.is_canadian ? 'Postal code' : 'Zip code'} <RequiredLabel /></Form.Label>
						{/* <FormControl
							id="customer-zip_postal"
							style={{ textTransform: 'uppercase' }}
							size="sm"
							defaultValue={ getValueFromInputData(inputData, 'customer-zip_postal') }
							name="customer-zip_postal"
							onChange={ (event) => this.props.inputChange({ event }) }
							as={ InputMask }
							mask={ business.is_canadian ? 'a9a 9a9' : '99999'}
						/> */}
						<IMaskInput
							id="customer-zip_postal"
							mask={business.is_canadian ? 'a0a 0a0' : '00000'}
							defaultValue={ getValueFromInputData(inputData, 'customer-zip_postal') }
							name="customer-zip_postal"
							unmask={true}
							onAccept={(value) => {
								this.props.inputChange({ otherValue: value, otherName: 'customer-zip_postal' });
							}}
							className='form-control form-control-sm'
						/>
					</Form.Group>
				</div>
			</Fragment>
		);
	}
}

export default ZipPostal;
